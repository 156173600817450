/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { servicePath } from './defaultValues';

const apiUpload = `${servicePath}/upload`;
const apiDelete = `${servicePath}/upload/files`;
const apiWebp = `${servicePath}/helpers/webp`;

export const saveImages = async (values) => {
  const { id, model, file, field, strapiToken, previous } = values;

  /*  CLEAN OLD IMAGES  */
  if (previous && previous !== 0) {
    try {
      await axios.delete(`${apiDelete}/${previous}`, {
        headers: {
          Authorization: `Bearer ${strapiToken}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  /*  CLEAN OLD IMAGES  */
  if (file.type.search('svg') >= 0 || file.type.search('pdf') >= 0 || file.type.search('webp') >= 0) {
    /*  SAVE NORMAL FILE   */
    const bodyFormData = new FormData();
    bodyFormData.append('files', file);
    bodyFormData.append('ref', model);
    bodyFormData.append('refId', id);
    bodyFormData.append('field', field);

    await axios({
      method: 'post',
      url: `${apiUpload}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${strapiToken}`,
      },
    });
    /*  SAVE NORMAL FILE   */
  } else {
    /* CONVERTING TO WEBP */
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      await axios
        .post(
          `${apiWebp}`,
          { file: reader.result },
          {
            headers: {
              Authorization: `Bearer ${strapiToken}`,
            },
          }
        )
        .then(async (webpRes) => {
          const buf = Buffer.from(webpRes.data.webp, 'base64');
          const blobImg = new Blob([buf], {
            type: `image/webp`,
          });

          const bodyWebFormData = new FormData();
          bodyWebFormData.append('files', blobImg, 'temp.webp');
          bodyWebFormData.append('ref', model);
          bodyWebFormData.append('refId', id);
          bodyWebFormData.append('field', field);
          await axios({
            method: 'post',
            url: `${apiUpload}`,
            data: bodyWebFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${strapiToken}`,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    /* CONVERTING TO WEBP */
  }
};
